// #region Global Imports
import React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { MemberContainer, MemberTitle, GoldButton, MemberContent } from "../../styled";
import { Text, Button } from "@Components";

// #endregion Local Imports

// #region Interface Imports
import { IBecomeGoldMember } from "./BecomeGoldMember";
// #endregion Interface Imports

export const BecomeAGoldMember: React.FunctionComponent<IBecomeGoldMember.IProps> = ({ t }) => {
    return (
        <MemberContainer>
            <div className="d-flex flex-column w-100">
                <MemberTitle>{t("common:settings.standartMemberTitle")}</MemberTitle>
                <MemberContent>
                    <div>
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.standartMemberText", { cost: 149.99 })}
                        </Text>
                    </div>
                    <GoldButton>
                        <Link
                            href="/payments/[type]/credit-card?source=profile"
                            as="/payments/gold/credit-card"
                        >
                            <a>
                                <Button styleType="ghost" size="small">
                                    {t("common:Header.buttons.gold")}
                                </Button>
                            </a>
                        </Link>
                    </GoldButton>
                </MemberContent>
            </div>
        </MemberContainer>
    );
};
